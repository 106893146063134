<template>
    <div class="cookie-wrapper">
        <div class="cookie-header">
            <img src="/images/cookies@2x.png" alt="">
            <h1>Our website uses cookies</h1>
        </div>
        <div class="cookie-text">
            <p>
                Our website uses cookies to understand how people use our
                website in order for us to improve our website. By ticking the
                box of our popup Analytics windows, you hereby consent to our
                use of cookies as defined in this Cookies Policy. If you do not
                consent to the use of Web Operations Cookies, you may block or
                disable them using your browser settings and by unchecking
                available checkobox, the opt-out links set out in this Cookies
                Policy. However, blocking all cookies may have a negative impact
                on your use of this website.
            </p>
        </div>
        <div class="cookie-footer">
            <el-checkbox v-model="webOperationsCheck" disabled
                >Website Operations</el-checkbox
            >
            <el-checkbox v-model="analyticsCheck">Analytics</el-checkbox>
            <button class="submit-button-cookie" @click="saveCookie()">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import { bootstrap } from "vue-gtag";
export default {
    data() {
        return {
            webOperationsCheck: true,
            analyticsCheck: true
        };
    },
    created() {
        if (this.$cookies.isKey("analytics-accepted")) {
            if (this.$cookies.get("analytics-acepted")) {
                bootstrap().then(gtag => {});
            }
        }
    },
    mounted() {
        if (!this.$cookies.get("gdpr-accepted")) {
            $(".cookie-wrapper").animate(
                {
                    right: "0"
                },
                800,
                function() {
                    // Animation complete.
                }
            );
        }
    },
    methods: {
        saveCookie() {
            this.$cookies.set("gdpr-accepted", true); //return this
            this.$cookies.set("analytics-acepted", this.analyticsCheck);
            if (this.analyticsCheck) {
               bootstrap().then(gtag => {});
            } 
            $(".cookie-wrapper").animate(
                {
                    right: "-100%"
                },
                800,
                function() {
                    // Animation complete.
                }
            );
        }
    }
};
</script>

<style lang="scss"></style>
