<template>
        <div>
            <img src="/assets/img/left-arrow.png" alt="" class="back-arrow" @click=" goBack()">
            <h1>{{postData.post_title}}</h1>
            <div class="scribbling-info">
                <span>Created at:</span>
                <span>{{postData.created_at | formatDate }}</span>
                <span>Created by:</span>
                <span>{{postData.user_name}}</span>
                <span>Category:</span>
                <span>{{postData.category_name_single }}</span>
            </div>
            <img :src="`/storage/${postData.media_path}`" alt="">
            <div class="post-content" v-html="postData.post_content">
            

            </div>
          
        </div>
</template>

<script>
export default {
    name: "SingleScribblingComponent",
    props:['post'],
    data(){
        return{
            postData:{}
        }
    },
    mounted() {
    //    $('.post-content').children().forEach(element => {
    //        console.log(element)
    //    });;
        this.postData = this.post;
    },
    methods:{
        goBack(){
            window.history.back();
        }
    }
}
</script>

<style scoped>

</style>
