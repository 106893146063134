<template>
        <div class="testimonial-component">
            <div>
                <div class="">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 testimonial-col ">
                        <carousel :items="1" :nav="false" v-if="testimonailData.length > 0" :autoplay="true">
                            <template >
                                <div v-for="item in testimonailData" class="testimonial-group">
                                    <h1>“{{item.testimonial_text}}”</h1>
                                    <h3>{{item.testimonial_owner}}</h3>
                                    <h3>-{{item.testimonial_owner_company}}-</h3>
                                </div>
                            </template>


                        </carousel>
                    </div>
                </div>

            </div>

        </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
    components:{carousel},
    name: "TestimonialsSlider",
    props:['testimonials'],
    data(){
        return{
            testimonailData:[]
        }
    },
    mounted(){
        this.testimonailData = this.testimonials;
    }
}
</script>

<style  lang="scss">
.testimonial-component{
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background: #018DFC;
    }
}

</style>
