<template>
    <div class="accordion-wrapper">
        <vsa-list :autoCollapse="true">
            <!-- Here you can use v-for to loop through items  -->
            <div
                v-for="item in servicesData"
                :key="item.id"
                :id="lowerAndUnderscore(item.service_title)"
                :style="{
                    backgroundColor: decideColorBg(item)
                }"
            >
                <vsa-item
                    :forceActive="lowerAndUnderscoreIsOpen(item.service_title)"
                >
                    <!-- <vsa-item > -->

                    <vsa-heading>
                        <div
                            class="cover-image-service"
                            @click="itemClicked(item)"
                            :style="{
                                backgroundImage:
                                    'url(' +
                                    JSON.stringify(
                                        '/storage/' +
                                            item.service_cover_img_path
                                    ) +
                                    ')',
                                backgroundColor: decideColor(item)
                            }"
                        >
                            <div class="overlay-text">
                                <h1>{{ item.service_title }}</h1>
                                <p>{{ item.service_description }}</p>
                            </div>
                        </div>

                        <!--                        <img :src="`/storage/${item.service_cover_img_path}`" alt="" class="service-cover-img">-->
                    </vsa-heading>

                    <vsa-content class="new">
                        <div class="container">
                            <div class="row" :id="`section-${item.id}`">
                                <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex video-service-col  service-item-col"
                                    v-if="
                                        item.service_title ===
                                            'Video Production'
                                    "
                                >
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/wwiuhbX4CfM"
                                        frameborder="0"
                                        allow="autoplay; encrypted-media"
                                        allowfullscreen=""
                                    ></iframe>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center service-item-col"
                                    v-for="mediaItem in item.media_files"
                                    v-else
                                >
                                    <div class="service-item">
                                        <div
                                            class="clickable-element"
                                            @click="
                                                openLink(
                                                    mediaItem.service_media_link
                                                )
                                            "
                                            v-if="
                                                mediaItem.media_type ===
                                                    'image' ||
                                                    mediaItem.media_type ===
                                                        '' ||
                                                    mediaItem.media_type ===
                                                        null
                                            "
                                        >
                                            <img
                                                :src="
                                                    `/storage/${mediaItem.media_path}`
                                                "
                                                alt=""
                                                class="service-item-img"
                                                @click="
                                                    openDialog(
                                                        mediaItem,
                                                        false,
                                                        item
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            v-else
                                            class="service-item-contain"
                                        >
                                            <!-- <div
                                                class="overlay-div-video"
                                                @click="openDialog(mediaItem)"
                                                v-if="!mobileCheck()"
                                            ></div>
                                            <img
                                                src="/assets/img/play.svg"
                                                alt=""
                                                class="play-button"
                                                v-if="!mobileCheck()"
                                            /> -->
                                            <!-- <video v-if="!mobileCheck()">
                                                <source
                                                    :src="
                                                        `/storage/${mediaItem.media_path}`
                                                    "
                                                    type="video/mp4"
                                                />
                                                <source
                                                    :src="
                                                        `/storage/${mediaItem.media_path}`
                                                    "
                                                    type="video/ogg"
                                                />
                                                Your browser does not support
                                                the video tag.
                                            </video>
                                            <video controls v-else>
                                                <source
                                                    :src="
                                                        `/storage/${mediaItem.media_path}`
                                                    "
                                                    type="video/mp4"
                                                />
                                                <source
                                                    :src="
                                                        `/storage/${mediaItem.media_path}`
                                                    "
                                                    type="video/ogg"
                                                />
                                                Your browser does not support
                                                the video tag.
                                            </video> -->
                                        </div>
                                    </div>
                                    <div class="service-item-info">
                                        <span
                                            class="clickable-element"
                                            @click="
                                                openLink(
                                                    mediaItem.service_media_link
                                                )
                                            "
                                            >{{ mediaItem.client_name }}</span
                                        >
                                        <p
                                            class="clickable-element"
                                            @click="
                                                openLink(
                                                    mediaItem.service_media_link
                                                )
                                            "
                                        >
                                            {{ mediaItem.client_project_name }}
                                        </p>
                                        <button
                                            v-if="mediaItem.service_media_link"
                                        >
                                            <a
                                                :href="
                                                    `${mediaItem.service_media_link}`
                                                "
                                                target="_blank"
                                                >LINK</a
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vsa-content>
                </vsa-item>
            </div>
        </vsa-list>
        <el-dialog
            :title="currentItemTitle"
            :visible.sync="centerDialogVisible"
            width="90%"
            top="10vh"
            center
        >
            <div class="dialog-video-wrapper" v-if="isCurrentItemVideo">
                <video controls>
                    <source
                        :src="`/storage/${currentItemPath}`"
                        type="video/mp4"
                    />
                    <source
                        :src="`/storage/${currentItemPath}`"
                        type="video/ogg"
                    />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div class="dialog-img-wrapper" v-else>
                <img :src="`/storage/${currentItemPath}`" alt="" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
} from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";
export default {
    props: ["services"],
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    },
    name: "ServicesAccordion",
    data() {
        return {
            servicesData: [],
            centerDialogVisible: false,
            currentItem: {},
            playable_ads_open: false,
            game_development_open: false,
            video_production_open: false,
            design_open: false,
            app_development_open: false,
            currentItemTitle: "",
            currentItemPath: "",
            isCurrentItemVideo: false,
            videoProdOpen: false
        };
    },
    created() {
        this.decideOpen();
    },
    mounted() {
        this.servicesData = this.services;
        let that = this;
        window.addEventListener('hashchange', function() {
            that.decideOpen()
}, false);
    },
    methods: {
        decideOpen() {
            let url = window.location.href;
            if (url.includes("playable_ads")) {
                this.playable_ads_open = true;
            } else if (url.includes("game_development")) {
                this.game_development_open = true;
            } else if (url.includes("video_production")) {
                this.video_production_open = true;
            } else if (url.includes("design")) {
                this.design_open = true;
            } else if (url.includes("playable_ads")) {
                this.app_development_open = true;
            }
        },
        decideColorBg(item) {
            console.log(item.service_title);
            let bgColor;
            let title = item.service_title;

            if (title === "Playable Ads") {
                bgColor = "#1C76F9";
            } else if (title === "Design") {
                bgColor = "#F76FA7";
            } else if (title === "Video Production") {
                bgColor = "#FFD400";
            }

            return bgColor;
            // }
        },
        decideColor(item) {
            let bgColor;
            let title = item.service_title;

            if (title === "Playable Ads") {
                bgColor = "#005BE0";
            } else if (title === "Design") {
                bgColor = "#FE6286";
            } else if (title === "Video Production") {
                bgColor = "#FFBC00";
            }

            return bgColor;
            // }
        },
        openLink(link) {
            if (link) {
                window.open(link, "_blank").focus();
            }
        },
        lowerAndUnderscore(string) {
            return string.replace(/\s+/g, "_").toLowerCase();
        },
        lowerAndUnderscoreIsOpen(string) {
            let property = string.replace(/\s+/g, "_").toLowerCase();
            let shouldOpen;
            if (property === "playable_ads") {
                shouldOpen = this.playable_ads_open;
            } else if (property === "game_development") {
                shouldOpen = this.game_development_open;
            } else if (property === "video_production") {
                shouldOpen = this.video_production_open;
            } else if (property === "design") {
                shouldOpen = this.design_open;
            } else if (property === "app_development") {
                shouldOpen = this.app_development_open;
            }

            return shouldOpen;
        },
        openDialog(item, isVideo = true, parent = null) {
            if (!this.mobileCheck()) {
                if (isVideo) {
                    this.isCurrentItemVideo = true;
                    this.currentItemTitle = "";
                    this.currentItemPath = "";

                    this.currentItemTitle = item.client_project_name;
                    this.currentItemPath = item.media_path;
                    this.centerDialogVisible = true;
                } else {
                    this.isCurrentItemVideo = false;
                    if (parent.service_title === "Design") {
                        this.currentItemTitle = item.client_project_name;
                        this.currentItemPath = "";

                        this.currentItemTitle = item.client_project_name;
                        this.currentItemPath = item.media_path;
                        this.centerDialogVisible = true;
                    } else {
                        if (item.service_media_link) {
                            window.open(`${item.service_media_link}`);
                        }
                    }
                }
            }
        },
        mobileCheck() {
            let check = false;
            (function(a) {
                if (
                    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                        a
                    ) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                        a.substr(0, 4)
                    )
                )
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        itemClicked(item) {
            // if (item.service_title === "Video Production") {
            //     // $(".video-service-col").addClass("scale-animation");
            //     return;
            // }

            let id = this.lowerAndUnderscore(item.service_title);
            $("html, body").animate(
                {
                    scrollTop: $(`#${id}`).offset().top
                },
                1000
            );
        }
    }
};
</script>

<style lang="scss">
.vsa-list {
    border: none;
    width: 100%;
    max-width: 100%;
}
.vsa-item__trigger {
    padding: 0;
    width: 100%;
}
.service-cover-img {
    width: 100%;
    height: 100%;
}
.vsa-item__trigger__icon {
    display: none;
}
.service-cover-img {
    width: 100%;
    height: 100%;
}
.vsa-item__trigger__content {
    width: 100%;
}
</style>
