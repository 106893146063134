/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
import axios from 'axios'
import VueAxios from 'vue-axios';

import moment from 'moment'

window.Vue.use(VueAxios, axios)


import VueCookies from 'vue-cookies'
window.Vue.use(VueCookies)
Vue.$cookies.config('7d','','',true)
// window.Vue.use(moment)

import Vue from 'vue'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-BVQ1PXYB2Y",
  enabled:false,
  bootstrap: false
}
});



window.Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MMM/DD/YYYY')
    }
});




import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

window.Vue.use(VueSlickCarousel);

window.Vue.use(ElementUI);

import gsap from "gsap";

window.Vue.use(gsap);

import $ from 'jquery'
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('testimonial-component', require('./components/TestimonialsSlider.vue').default);
Vue.component('services-accordion-component', require('./components/ServicesAccordion.vue').default);
Vue.component('our-work-slider-component', require('./components/OurWorkSlider.vue').default);
Vue.component('contact-us-component', require('./components/ContactUsForm.vue').default);
Vue.component('scribblings-component', require('./components/ScribblingsComponent.vue').default);
Vue.component('single-scribbling-component', require('./components/SingleScribblingComponent.vue').default);
Vue.component('cookie-box', require('./components/CookieBox.vue').default);
Vue.component('awesome-component', require('./components/AwesomeComponent.vue').default);








/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */



require('./custom');
// require('./ribbon-animation')
const app = new Vue({
    el: '#app',
});

require('./tria-site-header');
require('./animations');

setTimeout(()=>{

    const contactBackground = document.getElementById('contact-us-awesome-background');
    const contactHeader = new TriaSiteHeader(contactBackground, 'our-work-1');
    window.contactHeader = contactHeader;
    
    const heroBackground = document.getElementById('hero-section-background');
    const heroHeader = new TriaSiteHeader(heroBackground, 'home');
    window.heroHeader = heroHeader;

    const ourWorkBackground = document.getElementById('our-work-background');
    const ourWorkHeader = new TriaSiteHeader(ourWorkBackground, 'our-work-2');
    window.ourWorktHeader = ourWorkHeader;
    
    
    
},300)
