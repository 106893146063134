<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item prop="name">
                <el-input v-model="ruleForm.name" placeholder="Name"></el-input>
            </el-form-item>
            <el-form-item prop="email">
                <el-input v-model="ruleForm.email" placeholder="Email"></el-input>
            </el-form-item>
            <el-form-item prop="company">
                <el-input v-model="ruleForm.company" placeholder="Company"></el-input>
            </el-form-item>
            <el-form-item  prop="message" >
                <el-input type="textarea"  v-model="ruleForm.message" placeholder="Message"></el-input>
            </el-form-item>
            <el-form-item class="captcha">
                <vue-recaptcha  @verify="onVerify" :sitekey="siteKey">
                </vue-recaptcha>  
                <el-button type="primary" class="submit-button"  style="margin-top:30px;" @click="submitForm('ruleForm')">Submit</el-button>
            </el-form-item>
        </el-form>  
</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
    components:{VueRecaptcha},
    data() {
        return {
            siteKey:"6LfWivQaAAAAACOnWhb3UeM0zbQEt0Xz5YzGjf3_",
            ruleForm: {
                name: '',
                email: '',
                company: '',
                message: '',
            },
            captchaVerified: false,
            rules: {
                name: [
                    {required: true, message: 'Please input your name!', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: 'Please enter email!', trigger: 'blur'},
                    {type: 'email', message: 'Please enter valid email!', trigger: 'blur'},
                ],
                company: [
                    {required: true, message: 'Please enter company name!', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        onVerify(){
                this.captchaVerified = true;
        },
        submitForm(formName) {
            if(this.captchaVerified){
        this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios.post('/api/form', this.ruleForm)
                        .then(response => {
                            if(response.status == 200){
                                this.$message({
                                    message: 'Form successfully submitted!',
                                    type: 'success',
                                    offset:90
                                });
                            this.resetForm('ruleForm')

                            }else{
                                   this.$message({
                                    message: 'Oops, something went wrong check form and try again.',
                                    type: 'error',
                                    offset:90

                                });
                                // this.$message.error('');
                            }
                        }).catch(e=>{
                            this.$message({
                                    message: 'Oops, something went wrong check form and try again.',
                                    type: 'error',
                                    offset:90

                                });
                        })
                } else {
                      this.$message({
                                    message: 'Oops, something went wrong check form and try again.',
                                    type: 'error',
                                    offset:90

                                });
                    console.log('error submit!!');
                    return false;
                }
            });
            }else{
                  this.$message({
                                    message: 'Prove that you are not a robot.',
                                    type: 'error',
                                    offset:90

                                });
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="scss">
.el-form-item__content{
    margin: 0 !important;
    .el-input{
        .el-input__inner{
            border-radius: 0;
        }
    }
}
.el-message{
    max-width: 380px !important;
    min-width: 300px !important;
    width: 96% !important;
}

</style>
