<template>
<div id="awsome-component" :class="[isOffset ? 'scaleUp':'']">
    <img src="/assets/img/tria contact.svg" alt="Tria" class="hero-logo">
            <!-- <img src="/images/" alt="" class="flamingo"> -->
            <div class="hero-text-inside-wrapp">
            <img src="/images/flamingo.png" alt="" class="flamingo">
            <img src="/images/palmLeft@2x.png" alt=""  :class="[isOffset ? 'left-palm-offset left-palm' : 'left-palm']">
            <img src="/images/palmRight@2x.png" alt=""  :class="[isOffset ? 'right-palm-offset right-palm' : 'right-palm']">
            <h1>OUR WORK</h1>
            <h3>ENTER THE WORLD OF AWESOME!</h3>
            </div>
</div>
    
</template>
<script>
export default {
    props:['offset'],
    data(){
        return{
            isOffset:false
        }
    },
    created(){
        console.log(this.offset)
        this.isOffset = this.offset;
    }
}
</script>