<template>
    <div class="hero-wrapper" @mousemove="topContainerMouseMove">
        <div class="overlay-div"></div>
        <div class="our-work-slider-component" id="our-work-slider-container">
            <div class="our-work-wrapper">
                <div
                    class="col img-col"
                    v-for="(item, index) in portfolioDataTop"
                >
                    <img :src="`/storage/${item.media_path}`" alt="" />
                </div>
            </div>
        </div>
        <div
            class="our-work-slider-component-bot d-sm-none d-md-flex d-l-flex d-xl-flex "
            id="our-work-slider-container-bot"
        >
            <div class="our-work-wrapper">
                <div
                    class="col  img-col"
                    v-for="(item, index) in portfolioDataBot"
                >
                    <img :src="`/storage/${item.media_path}`" alt="" />
                </div>
            </div>
        </div>
        <div class="hero-text-wrapper">
            <img src="/assets/img/tria contact.svg" alt="Tria" />
            <h1>OUR WORK</h1>
            <h3>ENTER THE WORLD OF AWESOME!</h3>
            <button class="tria-button">
                <a href="/our-work">LEARN MORE</a>
            </button>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";

export default {
    name: "OurWorkSlider",
    props: ["portfolio"],
    data() {
        return {
            portfolioDataTop: [],
            portfolioDataBot: []
        };
    },
    mounted() {
        let firstRowNItems = Math.round(this.portfolio.length / 2);
        this.portfolioDataTop = this.portfolio.slice(0, firstRowNItems);
        this.portfolioDataBot = this.portfolio.slice(firstRowNItems);
    },
    methods: {
        topContainerMouseMove(e) {
            let wrapper = $(".hero-wrapper");
            let $this = null;
            const isMobileDevice = /iPhone | Android /i.test(
                window.navigator.userAgent
            );
            if (isMobileDevice) {
                $this = $("#our-work-slider-container");
            } else {
                if (wrapper.height() / 2 > e.offsetY) {
                    $this = $("#our-work-slider-container");
                } else {
                    $this = $("#our-work-slider-container-bot");
                }
            }

            let imgCol;
            let relX = e.pageX - $this.offset().left;
            let rightOffset;
            imgCol = $(".img-col").width();

            if (isMobileDevice) {
                imgCol = 300;
                if ($this.position().left > 1095) {
                    gsap.to($this, {
                        x: 1090,
                        duration: 0.5
                    });
                    return;
                } else if ($this.position().left < -1095) {
                    gsap.to($this, {
                        x: -1090,
                        duration: 0.5
                    });
                    return;
                }
            }
            if ($this.position().left < 0) {
                rightOffset = 80;
            } else {
                rightOffset = 50;
            }
            gsap.to($this, {
                x: ((relX - $this.width() / 2) / $this.width()) * -(imgCol + 70),
                duration: 1
                //   ease: Power2.easeOut
            });
            //    }
        }
    }
};
</script>

<style lang="scss"></style>
