<template>
        <div id="scribbling">
            <div class="">
                <div class="tabs-header tab-header-lg-md">
                    <div class="tabs-col-input">
                        <input type="text" id="input-search" v-model="search" placeholder="SEARCH"/>
                        <img src="/assets/img/search_icon.svg" alt="">
                    </div>
                    <div v-for="item in categoryData" class="tabs-col" :class="{activeTab:item.id == selectedCategoryId}" @click="selectCategory(item,$event)">
                        <a href="#" onClick="return false;" class="tabs">{{ item.category_name }}</a>
                    </div>
                </div>
                    <div class="tabs-header tab-header-sm">
                        <div v-for="(item,index) in categoryData" class="tabs-col tabs-m-bot" :class="{activeTab:item.id == selectedCategoryId}" v-if="index === 0"  @click="selectCategory(item,$event)">
                            <a href="#" onClick="return false;" class="tabs" >{{ item.category_name }}</a>
                        </div>
                    </div>
                <div class="tabs-header tab-header-sm">
                    <div v-for="(item,index) in categoryData" class="tabs-col" :class="{activeTab:item.id == selectedCategoryId}"  v-if="index > 0 && index < categoryData.length - 1"  @click="selectCategory(item,$event)">
                        <a href="#" onClick="return false;" class="tabs" >{{ item.category_name }}</a>
                    </div>
                </div>
                <div class="tabs-header tab-header-sm">
                    <div v-for="(item,index) in categoryData" class="tabs-col  tabs-m-top" :class="{activeTab:item.id == selectedCategoryId}" v-if="index === categoryData.length - 1" @click="selectCategory(item,$event)">
                        <a href="#" onClick="return false;" class="tabs" >{{ item.category_name }}</a>
                    </div>
                </div>
                <div class="tabs-header tab-header-sm">
                    <div class="tabs-col-input">
                        <input type="text"  v-model="search" placeholder="SEARCH"/>
                        <img src="/assets/img/search_icon.svg" alt="">
                    </div>
                </div>

                    <div class="content-wrapper">
                        <div v-for="item in filteredResources" class="scribbling-post-wrapper" v-if="filteredResources" @click="visitPost(item)">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12 col-12">
                                <div class="post-img" :style="{ backgroundImage: 'url('+JSON.stringify('/storage/'+item.media_path)+')' }">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12 col-12">
                                    <div class="post-text">
                                    <h1>{{item.post_title}}</h1>
                                        <p class="scribblings-post-content">{{stripTags(item.post_content) | threeDots}}</p>
                                        <span>{{item.created_at | formatDate }}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="item in selectedItems" class="scribbling-post-wrapper" v-else @click="visitPost(item)">
                            <div class="row">
                                 <div class="col-lg-6 col-md-12 col-12 col-12">
                                    <div class="post-img" :style="{ backgroundImage: 'url('+JSON.stringify('/storage/'+item.media_path)+')' }">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-12 col-12">
                                    <div class="post-text">
                                        <h1>{{item.post_title}}</h1>
                                        <p class="scribblings-post-content">{{stripTags(item.post_content) | threeDots}}</p>
                                        <span>{{item.created_at | formatDate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
</template>

<script>
export default {
    name: "ScribblingComponent",
    props: ['data'],
    data() {
        return {
            search: '',
            categoryData: [],
            allPosts: [],
            selectedItems: [],
            selectedCategoryId: 0
        }
    },
    created() {
        this.categoryData = this.data;
        this.allPosts = this.data[0];
        this.selectCategory(this.categoryData[0]);
    },
    methods: {
        stripTags(string){
                return window.$(`${string}`).text();
        },
        selectCategory(item, event) {
            let sortedPost = item.post.sort((a,b)=>{
                 return new Date(b.created_at) - new Date(a.created_at);
            })
            this.selectedItems = [];
            this.selectedItems = sortedPost;
            this.selectedCategoryId = item.id;
        },
        visitPost(item){
            window.location.href = `/scribbling/${item.id}`
        }
    },
    computed:{
        filteredResources() {
            if (this.search) {
                return this.selectedItems.filter((post) => {
                    return post.post_title.toLowerCase().includes(this.search.toLowerCase())
                })
            } else {
                return this.selectedItems;
            }
        }
    },
    filters:{
        threeDots(value){
            let dots = "...";
            let limit = screen.width < 1024 ? 600 : 800;
            if(value.length > limit)
            {
                // you can also use substr instead of substring
                value = value.substring(0,limit) + dots;
            }

            return value;
        }
    }
}
</script>

<style >
</style>
