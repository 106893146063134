///Active state in menu




let path = window.location.href ;

$(".nav-item").on("click", function(){
    $('li.active').removeClass('active');
    $(this).addClass("active");
});
$(document).ready(function() {
    $('li.active').removeClass('active');
    $('a[href="/' + path.split('/')[3] + '"]').closest('li').addClass('active');
});


window.is_welcome_page = !path.includes('scribbling') && !path.includes('our-work')
    && !path.includes('privacy-policy') && !path.includes('terms-and-conditions');
if(is_welcome_page){
    $('.welcome-bg').css('visibility','visible');
}
let navbar = document.getElementById("navigation");
let sticky = navbar.offsetTop;


$(".what-we-do-desc").each(function(i, el) {
    var el = $(el);
    el.text(add3Dots(el.text(),200));
});
$(".scribblings-post-content").each(function(i, el) {
    var el = $(el);
    el.text(add3Dots(el.text(),100));
});

function add3Dots(string, limit)
{
    var dots = "...";
    if(string.length > limit)
    {
        // you can also use substr instead of substring
        string = string.substring(0,limit) + dots;
    }

    return string;
}


// import './tria-site-header' ;
// new TriaSiteHeader(document.body, 'home');
// $('h1').each(function(){
//     $(this).css({"left": Math.random() * window.outerWidth , "top": Math.random() * window.outerHeight}).textillate();
// });
// window.onscroll = function(e) {
//
//
//    if(this.oldScroll > this.scrollY){
//        navbar.classList.add("sticky")
//        navbar.classList.remove("absolute-bg");
//
//    } else{
//        navbar.classList.add("absolute-bg")
//        navbar.classList.remove("sticky");
//    }
//     // if(this.scrollY === 0){
//     //     navbar.classList.remove("sticky");
//     // }
//     this.oldScroll = this.scrollY;
// }



