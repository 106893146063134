var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion-wrapper"},[_c('vsa-list',{attrs:{"autoCollapse":true}},_vm._l((_vm.servicesData),function(item){return _c('div',{key:item.id,style:({
                backgroundColor: _vm.decideColorBg(item)
            }),attrs:{"id":_vm.lowerAndUnderscore(item.service_title)}},[_c('vsa-item',{attrs:{"forceActive":_vm.lowerAndUnderscoreIsOpen(item.service_title)}},[_c('vsa-heading',[_c('div',{staticClass:"cover-image-service",style:({
                            backgroundImage:
                                'url(' +
                                JSON.stringify(
                                    '/storage/' +
                                        item.service_cover_img_path
                                ) +
                                ')',
                            backgroundColor: _vm.decideColor(item)
                        }),on:{"click":function($event){return _vm.itemClicked(item)}}},[_c('div',{staticClass:"overlay-text"},[_c('h1',[_vm._v(_vm._s(item.service_title))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.service_description))])])])]),_vm._v(" "),_c('vsa-content',{staticClass:"new"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"id":("section-" + (item.id))}},[(
                                    item.service_title ===
                                        'Video Production'
                                )?_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-12 d-flex video-service-col  service-item-col"},[_c('iframe',{attrs:{"width":"100%","height":"100%","src":"https://www.youtube.com/embed/wwiuhbX4CfM","frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})]):_vm._l((item.media_files),function(mediaItem){return _c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center service-item-col"},[_c('div',{staticClass:"service-item"},[(
                                            mediaItem.media_type ===
                                                'image' ||
                                                mediaItem.media_type ===
                                                    '' ||
                                                mediaItem.media_type ===
                                                    null
                                        )?_c('div',{staticClass:"clickable-element",on:{"click":function($event){return _vm.openLink(
                                                mediaItem.service_media_link
                                            )}}},[_c('img',{staticClass:"service-item-img",attrs:{"src":("/storage/" + (mediaItem.media_path)),"alt":""},on:{"click":function($event){return _vm.openDialog(
                                                    mediaItem,
                                                    false,
                                                    item
                                                )}}})]):_c('div',{staticClass:"service-item-contain"})]),_vm._v(" "),_c('div',{staticClass:"service-item-info"},[_c('span',{staticClass:"clickable-element",on:{"click":function($event){return _vm.openLink(
                                                mediaItem.service_media_link
                                            )}}},[_vm._v(_vm._s(mediaItem.client_name))]),_vm._v(" "),_c('p',{staticClass:"clickable-element",on:{"click":function($event){return _vm.openLink(
                                                mediaItem.service_media_link
                                            )}}},[_vm._v("\n                                        "+_vm._s(mediaItem.client_project_name)+"\n                                    ")]),_vm._v(" "),(mediaItem.service_media_link)?_c('button',[_c('a',{attrs:{"href":("" + (mediaItem.service_media_link)),"target":"_blank"}},[_vm._v("LINK")])]):_vm._e()])])})],2)])])],1)],1)}),0),_vm._v(" "),_c('el-dialog',{attrs:{"title":_vm.currentItemTitle,"visible":_vm.centerDialogVisible,"width":"90%","top":"10vh","center":""},on:{"update:visible":function($event){_vm.centerDialogVisible=$event}}},[(_vm.isCurrentItemVideo)?_c('div',{staticClass:"dialog-video-wrapper"},[_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":("/storage/" + _vm.currentItemPath),"type":"video/mp4"}}),_vm._v(" "),_c('source',{attrs:{"src":("/storage/" + _vm.currentItemPath),"type":"video/ogg"}}),_vm._v("\n                Your browser does not support the video tag.\n            ")])]):_c('div',{staticClass:"dialog-img-wrapper"},[_c('img',{attrs:{"src":("/storage/" + _vm.currentItemPath),"alt":""}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }